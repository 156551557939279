
import ConnectorDetails from "@/components/connectors/ConnectorDetails"
import AutodeskConnectionButton from "@/components/connectors/AutodeskConnectionButton"
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    ConnectorDetails,
    AutodeskConnectionButton,
  },
  computed: {
    ...mapStores(useConnectorStore, useAccountStore),
  },
  methods: {
    onRemoveConnectionClick() {
      this.$analytics.saveEvent(
        AnalyticsEvent.SettingsConnectorsRemoveAutodeskConnection
      )
      this.connectorStore.revokeAutodeskConnection()
    },
  },
}
