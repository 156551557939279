
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import Vue from "vue"
import { AnalyticsEvent } from "@evercam/shared/types"

export default Vue.extend({
  components: { CopyToClipboardBtn },
  methods: {
    onCopyContactUsClicked() {
      this.$analytics.saveEvent(
        AnalyticsEvent.SettingsConnectorsContactUsButtonClick
      )
    },
  },
})
